import React from "react";
import  { Outline }  from "../../styles/Styles";

const Resume = () => {

  return (
    <div id="resume">
       <Outline>
       <a href="https://docs.google.com/document/d/1yIj3v-auWwRtl1c8PikZNHBjr5PvkoXtcOY7j9OFkPs/edit?usp=sharing">
          Resume</a>
       </Outline>
      </div>
  );
}

export default Resume